const toMoney = function (number) {
  if (number === null) return null;
  else return parseFloat(number);
};

const toPercent = function (number) {
  if (number === null || isNaN(number)) return null;
  else return +parseFloat(number * 100).toFixed(2);
};

const toFloat = (number) => {
  if (number === null || isNaN(number)) return null;
  else return +parseFloat(number / 100).toFixed(4);
};

export { toMoney, toPercent, toFloat };
