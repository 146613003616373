<template>
  <b-card>
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-form></b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group id="input-name">
              <label for="name">Name: <span class="text-danger">*</span></label>
              <b-form-input
                id="name"
                v-model="editedItem.name"
                type="text"
                :state="nameValidate"
                aria-describedby="input-name-feedback"
                placeholder="Banner name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-name-feedback">
                {{ invalidNameMessage }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-name">
              <label for="priority">Priority:</label>
              <b-form-input
                id="priority"
                v-model="editedItem.priority"
                type="text"
                placeholder="Priority"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-affiliate" label="Affiliate:" label-for="affiliate">
              <b-form-radio-group
                id="affiliate"
                v-model="editedItem.is_affiliate"
                :options="affiliateList"
                name="affiliate"
                size="lg"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="input-screen" label="Screen:" label-for="screen">
              <b-form-select v-model="selectedScreen" :options="screenAdsList">
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select screen --</b-form-select-option>
                </template></b-form-select
              >
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="input-position">
              <label for="position">Position: <span class="text-danger">*</span></label>
              <b-form-select
                v-model="selectedPos"
                :options="posList"
                :state="posValidate"
                aria-describedby="input-pos-feedback"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select position --</b-form-select-option>
                </template></b-form-select
              >
              <b-form-invalid-feedback id="input-pos-feedback">
                {{ invalidPosMessage }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="input-from-date" label="From Date:" label-for="from-date">
              <datePicker v-model="editedItem.from_date" :config="dpOptions"></datePicker>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="input-to-date" label="To Date:" label-for="to-date">
              <datePicker v-model="editedItem.to_date" :config="dpOptions"></datePicker>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="input-img-url" label="Image URL:" label-for="img-url" aria-describedby="input-image-help">
              <b-form-input
                id="img-url"
                v-model="editedItem.image_url"
                type="text"
                placeholder="Image URL"
              ></b-form-input>
              <b-form-text id="input-image-help">Enter exist image link or upload new image file.</b-form-text>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="input-img-file" label="Image File:" label-for="img-file">
              <b-form-file
                v-model="editedItem.image_file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group id="input-target-url" label="Target URL:" label-for="target-url">
              <b-form-input
                id="target-url"
                v-model="editedItem.target_url"
                type="text"
                placeholder="Target URL"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group id="input-display-text" label="Display Text:" label-for="target-display-text">
              <b-form-textarea
                id="target-display-text"
                v-model="editedItem.display_text"
                rows="2"
                placeholder="Display Text"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group id="input-target-type" label="Open type:" label-for="target-type">
              <b-form-radio-group
                id="target-type"
                v-model="editedItem.target_type"
                :options="targetTypeList"
                name="target-type"
                size="lg"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Banner List</h4>
      </b-col>
      <b-col cols="4">
        <b-form-group id="input-name-filter" label="Banner" label-for="name-filter">
          <b-form-input
            id="name-filter"
            v-model="filter_name"
            type="text"
            placeholder="Search banner ..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group id="input-screen-filter" label="Screen" label-for="screen-filter">
          <b-form-select id="screen-filter" v-model="filter_screen" :options="screenAdsList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="4" class="btnAdd">
        <b-button variant="primary" class="float-right" @click="addItem">Add</b-button>
      </b-col>
    </b-row>
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table"
    >
      <template v-slot:cell(screen)="item">
        {{ item.value.name }}
      </template>
      <template v-slot:cell(target_url)="item">
        <a :href="item.value" target="_blank">{{ item.value.slice(0, 25) }}{{ item.value.length ? '...' : '' }}</a>
      </template>
      <template v-slot:cell(image_preview)="item">
        <img :src="item.value[0].src" style="height: 50px" @click="openGallery(item.item.id)" />
        <LightBox
          :ref="'lightbox_' + item.item.id"
          :media="item.value"
          :show-light-box="false"
          :show-thumbs="false"
        ></LightBox>
      </template>
      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger">Deleted</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success" @click="editItem(item.item)">mdi-pencil</v-icon>
        <v-icon class="text-danger" @click="deleteItem(item.item)">mdi-delete</v-icon>
      </template>
    </b-table>
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
// import { mapState } from "vuex";
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import ApiService from '@/common/api.service.js';
import LightBox from 'vue-image-lightbox';
import _ from 'lodash';
import axios from 'axios';
import { toMoney } from '@/utils/number.js';
import { CurrentTime, formatDateForView, formatDateForDb } from '@/utils/time.js';
export default {
  name: 'Banner List',
  components: {
    datePicker,
    LightBox
  },
  data() {
    return {
      search: '',
      isBusy: false,
      filter_name: '',
      filter_screen: null,
      filter_status: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Name', key: 'name' },
        { text: 'Screen', key: 'screen' },
        { text: 'Position', key: 'position' },
        { text: 'From Date', key: 'from_date' },
        { text: 'To Date', key: 'to_date' },
        { text: 'Display Text', key: 'display_text' },
        { text: 'Target URL', key: 'target_url' },
        { text: 'Image URL', key: 'image_preview' },
        { text: 'Priority', key: 'priority' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      screenAdsList: [],
      posList: [
        {
          value: 'pos_01',
          text: 'Top/Left banner'
        }
      ],
      affiliateList: [
        { value: 1, text: 'Yes' },
        { value: 0, text: 'No' }
      ],
      targetTypeList: [
        { value: '_self', text: 'Open in same tab' },
        { value: '_blank', text: 'Open in new tab' }
      ],
      selectedScreen: null,
      selectedPos: null,
      modalTitle: '',
      // Modal validate
      nameValidate: null,
      posValidate: null,
      invalidNameMessage: '',
      invalidPosMessage: '',
      // loading: false,
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        name: '',
        screen_id: '',
        code: '',
        from_date: '',
        to_date: '',
        image_url: '',
        target_url: '',
        display_text: '',
        is_affiliate: 0,
        status: 1,
        image_file: null,
        priority: 1,
        target_type: '_self',
        screen: ''
      },
      defaultItem: {
        id: '',
        name: '',
        screen_id: '',
        code: '',
        from_date: '',
        to_date: '',
        image_url: '',
        target_url: '',
        display_text: '',
        is_affiliate: 0,
        status: 1,
        image_file: null,
        priority: 1,
        target_type: '_self',
        screen: ''
      },
      dialog: false,
      editedIndex: -1,
      dpOptions: {
        format: 'HH:mm DD/MM/YYYY',
        sideBySide: true
      }
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    openGallery(id) {
      this.$refs[`lightbox_${id}`].showImage(0);
    },
    fetchData() {
      // this.loading = true;
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const adsReq = ApiService.get(
        'banners',
        `?name=${this.filter_name}&screen=${this.filter_screen || ''}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&offset=${offset}&limit=${limit}`
      );
      const screenReq = ApiService.get('allscreens');
      axios
        .all([adsReq, screenReq])
        .then(
          axios.spread((...response) => {
            const adsRes = response[0];
            const screenRes = response[1];
            this.items = adsRes.data.data.screenAdsList.map((ads) => {
              return {
                ...ads,
                from_date: formatDateForView(ads.from_date),
                to_date: formatDateForView(ads.to_date),
                image_preview: [{ thumb: ads.image_url, src: ads.image_url }]
              };
            });
            this.rows = adsRes.data.data.screenAdsCount;
            this.screenAdsList = screenRes.data.data;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    addItem: function () {
      this.dialog = true;
      this.modalTitle = 'Add Banner';
    },
    editItem: function (item) {
      this.modalTitle = 'Update Banner';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.selectedScreen = item.screen_id;
      this.selectedPos = item.code;
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (confirm('Are you sure you want to delete this item?')) {
        ApiService.delete(`banners/${item.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.editedIndex = this.items.indexOf(item);
              this.editedItem = {
                ...item
              };
              this.editedItem.deleted_at = new Date();
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.$bvToast.toast('Banner deleted!', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.items[index].status = 0;
            }
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          });
      }
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.selectedScreen = null;
      this.selectedPos = null;
      this.nameValidate = null;
      this.posValidate = null;
      this.invalidNameMessage = '';
      this.invalidPosMessage = '';
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      // Validate required fields
      let allValidated = true;
      if (this.editedItem.name == '') {
        this.nameValidate = false;
        this.invalidNameMessage = 'Please input banner name';
        allValidated = false;
      }
      if (this.selectedPos === null) {
        this.posValidate = false;
        this.invalidPosMessage = 'Please select banner position';
        allValidated = false;
      }
      if (allValidated) {
        // Get new brand/cate/vendor name for data-table
        this.editedItem.screen_id = this.selectedScreen;
        let formData = new FormData();
        formData.append('name', this.editedItem.name);
        formData.append('screen_id', this.editedItem.screen_id);
        formData.append('code', this.selectedPos);
        formData.append('from_date', this.editedItem.from_date ? formatDateForDb(this.editedItem.from_date) : null);
        formData.append('to_date', this.editedItem.to_date ? formatDateForDb(this.editedItem.to_date) : null);
        formData.append('image_url', this.editedItem.image_url);
        formData.append('image_file', this.editedItem.image_file);
        formData.append('target_url', this.editedItem.target_url);
        formData.append('display_text', this.editedItem.display_text);
        formData.append('is_affiliate', this.editedItem.is_affiliate);
        formData.append('priority', toMoney(this.editedItem.priority || 1));
        formData.append('target_type', this.editedItem.target_type);
        formData.append('screen', this.editedItem.screen);
        if (this.editedIndex > -1) {
          // Update banner
          formData.append('updated_at', CurrentTime());
          axios({
            method: 'PUT',
            url: `banners/${this.items[this.editedIndex].id}`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          })
            .then((response) => {
              if (response.status === 204) {
                this.screenAdsList.some((screen) => {
                  if (screen.value == this.selectedScreen) {
                    this.editedItem.screen = {
                      id: screen.value,
                      name: screen.text
                    };
                    return true;
                  }
                });
                this.editedItem.image_preview = [
                  {
                    thumb: this.editedItem.image_url,
                    src: this.editedItem.image_url
                  }
                ];
                Object.assign(this.items[this.editedIndex], this.editedItem);
                this.close();
                this.$bvToast.toast('Banner updated successfully', {
                  title: null,
                  variant: 'success',
                  autoHideDelay: 3000,
                  noCloseButton: true
                });
              } else {
                this.$bvToast.toast(`errors`, {
                  title: null,
                  variant: 'danger',
                  autoHideDelay: 3000,
                  noCloseButton: true
                });
              }
            })
            .catch((errors) => {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            });
        } else {
          // Add banner
          formData.append('status', 1);
          axios({
            method: 'POST',
            url: 'banners',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          })
            .then((response) => {
              if (response.data.status) {
                this.screenAdsList.some((screen) => {
                  if (screen.value == response.data.data.screen_id) {
                    response.data.data.screen = {
                      id: screen.value,
                      name: screen.text
                    };
                  }
                });
                this.items.unshift({
                  ...response.data.data,
                  image_preview: [
                    {
                      thumb: response.data.data.image_url,
                      src: response.data.data.image_url
                    }
                  ],
                  from_date: formatDateForView(response.data.data.from_date),
                  to_date: formatDateForView(response.data.data.to_date)
                });
                this.close();
                this.$bvToast.toast('Banner added successfully', {
                  title: null,
                  variant: 'success',
                  autoHideDelay: 3000,
                  noCloseButton: true
                });
              } else {
                this.$bvToast.toast(`errors`, {
                  title: null,
                  variant: 'danger',
                  autoHideDelay: 3000,
                  noCloseButton: true
                });
              }
            })
            .catch((errors) => {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            });
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Banners',
        route: 'banners'
      },
      { title: 'Banner List' }
    ]);
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  }
};
</script>
